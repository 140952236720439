import { z } from 'zod';

export const getZodDefaults = <Schema extends z.AnyZodObject>(schema: Schema) =>
  Object.fromEntries(
    Object.entries(schema.shape).map(([key, value]) => {
      if (value instanceof z.ZodDefault)
        return [key, value._def.defaultValue()];
      return [key, undefined];
    }),
  );
