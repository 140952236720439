import { queryOptions } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';

export const queryApplicationOptions = (applicationId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.UserApplications_getApplication({ applicationId })).data,
    queryKey: ['application', applicationId],
  });
