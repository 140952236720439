import { Bars3Icon } from '@heroicons/react/20/solid/index';
import { Flask } from '@phosphor-icons/react';
import { Link } from '@tanstack/react-router';
import { ReactNode } from 'react';

import { useTheming } from '@/common/hook/useTheming.ts';

type AppBarProps = {
  endSlot?: ReactNode;
  onHamburgerClick?: () => void;
};

const AppBar = ({ endSlot, onHamburgerClick }: AppBarProps) => {
  const { bannerImageDark } = useTheming();
  return (
    <div
      className={
        'bg-primary-foreground w-full h-app-bar flex justify-between items-center dark fixed z-10'
      }
    >
      {!!onHamburgerClick && (
        <div className="lg:hidden flex items-center">
          <button
            aria-label="Open sidebar"
            type="button"
            onClick={() => onHamburgerClick()}
            className="p-3"
            aria-haspopup="true"
            aria-controls="sidebar"
          >
            <Bars3Icon className="text-white h-6" />
          </button>
        </div>
      )}
      <div className={'flex items-center justify-start h-full'}>
        <Link to={'/'} className={'h-full p-6 ml-5 text-white block'}>
          <img src={bannerImageDark} className={'h-full'} />
        </Link>
        {import.meta.env.VITE_ENVIRONMENT === 'development' && (
          <div
            className={
              'px-1 py-1 sm:py-0.5 bg-warning border border-warning-foreground/60 text-warning-foreground sm:rounded-md font-mono flex items-center text-sm absolute rounded-bl-md sm:static top-0 right-0 rounded-tr-none sm:rounded-tr-md border-t-0 sm:border-t rounded-none sm:border-r border-r-0'
            }
          >
            <Flask className={'h-4 w-4 inline-block sm:mr-1 rotate-12'} />
            <span className={'hidden sm:inline'}>SANDBOX</span>
          </div>
        )}
      </div>
      <div className={'flex items-center justify-end mr-6 min-w-9 min-h-9'}>{endSlot}</div>
    </div>
  );
};

export default AppBar;
