import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getTeamMemberUserQuery } from '@/features/administrator-panel/team/team-member-view/api/teamMemberApi.ts';
import { fullName } from '@/lib/fullName.ts';

export const Route = createFileRoute('/admin/configuration/team/$userId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getTeamMemberUserQuery(params.userId)),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData ? fullName(loaderData.individual) : 'Team Member',
      },
    ],
  }),
});
