import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getProxyQuery } from '@/features/administrator-panel/proxy/api/proxiesApi.ts';

export const Route = createFileRoute('/admin/configuration/proxy/$proxyId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getProxyQuery(params.proxyId)),
  head: ({ loaderData }) => ({
    meta: [{ title: loaderData ? loaderData.name : 'Proxy Configuration' }],
  }),
});
