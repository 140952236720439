import { useTheming } from '@/common/hook/useTheming.ts';
import Dialog, { DialogProps } from '@/components/ui/Dialog.tsx';
import { Typography } from '@/components/ui/Typography.tsx';

export const GetHelpDialog = ({
  open,
  onClose,
}: Pick<DialogProps, 'onClose' | 'open'>) => {
  const { supportEmail, supportPhone } = useTheming();

  return (
    <Dialog open={open} onClose={onClose} size={'small'}>
      <Typography variant={'h2'} className={'text-center mb-2'}>
        Need Help?
      </Typography>

      <Typography>
        If you need contact support, you can reach out to us at:
      </Typography>

      <Typography>
        <span className={'font-semibold'}>Email: </span>
        <span>{supportEmail}</span>
        <br />

        <span className={'font-semibold'}>Phone: </span>
        <span>{supportPhone}</span>
      </Typography>
    </Dialog>
  );
};
