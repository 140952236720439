import Spinner from '@/components/ui/Spinner.tsx';
import { useRouterState } from '@tanstack/react-router';

export const RouterLoadingIndicator = () => {
  const routerState = useRouterState();

  return <div
    className={`fixed top-0 left-0 w-full
        transition-all duration-300 pointer-events-none
        z-30 h-[200px] !bg-muted/20 rounded-[100%] ${
      routerState.isLoading
        ? 'delay-0 opacity-1 -translate-y-1/2'
        : 'delay-300 opacity-0 -translate-y-full'
    }`}
    style={{
      background: `radial-gradient(closest-side, rgba(0,10,40,0.2) 0%, rgba(0,0,0,0) 100%)`,
    }}
  >
    <div
      className={`absolute top-1/2 left-1/2 -translate-x-1/2 translate-y-[30px] p-2 bg-card
        rounded-lg shadow-lg`}
    >
      <Spinner className="w-8 h-8" />
    </div>
  </div>
}
