import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId/new-transfer',
)({
  component: () => (
    <div className={'p-4'}>
      <Outlet />
    </div>
  ),
  head: () => ({ meta: [{ title: 'New Transfer' }] }),
});
