import { SignIn } from '@phosphor-icons/react';
import { useMutation } from '@tanstack/react-query';

import { crownApi } from '@/common/api/crownQueryClient.ts';
import { Button } from '@/components/ui/Button.tsx';
import { Typography } from '@/components/ui/Typography.tsx';
import { Components } from '@/openapi';

type TestingUserRowProps = {
  user: Components.Schemas.TestUserDto;
};

export const TestingUserRow = ({ user }: TestingUserRowProps) => {
  const { mutate: loginAsUser, isPending } = useMutation({
    mutationFn: async () =>
      (await crownApi.Testing_generateTestUserLoginLink({ userId: user.id }))
        .data.link,
    onSuccess: (link) => {
      window.location.href = link;
    }
  });

  return (
    <div className={'grid grid-cols-3 p-4'}>
      <div className={'col-span-1 flex flex-col justify-center items-left'}>
        <Typography
          variant={'h4'}
        >{`${user.firstName} ${user.lastName}`}</Typography>
        <Typography variant={'muted'}>{user.email}</Typography>
      </div>
      <div className={'flex items-center'}>
        <Typography className={'text-muted-foreground text-lg'}>
          {user.description}
        </Typography>
      </div>
      <div className={'flex justify-end items-center'}>
        <Button onClick={() => loginAsUser()} loading={isPending}>
          Log in as user
          <SignIn className={'w-4 h-4 ml-2'} />
        </Button>
      </div>
    </div>
  );
};
