import { Router } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getUserSession } from '@/common/user-session/userSessionApi.ts';

export const trackMatomoPageChange = (router: Router<any, any, any>) => {
  if (
    !import.meta.env.VITE_MATOMO_SITE_ID ||
    !import.meta.env.VITE_MATOMO_URL
  ) {
    return;
  }
  try {
    const pathMatch = router.state.matches[router.state.matches.length - 1];
    const user = crownQueryClient.getQueryData<
      Awaited<ReturnType<typeof getUserSession>>
    >(['session']);
    const params = new URLSearchParams({
      idsite: import.meta.env.VITE_MATOMO_SITE_ID,
      rec: '1',
      action_name: pathMatch.routeId,
      url: window.location.origin + pathMatch.pathname,
      urlref: document.referrer,
      res: `${window.screen.width}x${window.screen.height}`,
      uid: user?.id || '',
    });
    void window.fetch(import.meta.env.VITE_MATOMO_URL + '?' + params.toString());
  } catch(error) {
    console.error('Tracking failure', error);
  }
};
