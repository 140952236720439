import { Link, NavigateOptions, useMatch } from '@tanstack/react-router';
import { ChevronRight } from 'lucide-react';
import { FC } from 'react';

import { Collapse } from '@/components/ui/Collapse.tsx';
import { cn } from '@/lib/utils.ts';

export type SidebarItemProps = {
  label: string;
  icon: FC<{ className?: string }>;
  iconClassName?: string;
  onClick?: () => void;
  notificationCount?: number;
  exactActiveMatch?: boolean;
  subItems?: SidebarItemProps[];
  isSubItem?: boolean;
} & Pick<NavigateOptions, 'to' | 'search'>;

const SidebarItem = ({
  to,
  search,
  icon: Icon,
  label,
  onClick,
  notificationCount,
  iconClassName,
  exactActiveMatch,
  subItems = [],
  isSubItem = false,
}: SidebarItemProps) => {
  const route = useMatch({
    from: '/admin/configuration',
    shouldThrow: false,
  });
  const expanded = route !== undefined;
  return (
    <>
      <Link
        to={to}
        params={undefined as never}
        search={search || undefined}
        className={cn(
          'group flex justify-start p-4 py-3 items-center text-base font-medium text-gray-800 transition-colors',
          {
            'pl-1': isSubItem,
            'hover:[&>svg]:text-white hover:text-white hover:bg-primary':
              subItems.length === 0,
          },
        )}
        activeProps={
          subItems.length === 0
            ? {
                className: 'bg-primary text-white [&>svg]:text-white',
              }
            : undefined
        }
        activeOptions={{ exact: exactActiveMatch }}
        onClick={onClick}
      >
        <Icon
          className={cn(
            'w-6 h-6 mr-2 ml-2 text-primary transition-colors',
            iconClassName,
            {
              'group-hover:text-white': subItems.length === 0
            }
          )}
        />
        {!!notificationCount && (
          <span className="absolute h-7 w-7 right-3 -ml-3 font-semibold rounded-full text-xs text-primary bg-primary-foreground border border-primary flex justify-center items-center items">
            <span>{notificationCount > 99 ? '99+' : notificationCount}</span>
          </span>
        )}
        <span className={'flex-grow pr-6'}>{label}</span>
        {subItems.length > 0 && (
          <ChevronRight
            className={cn('w-5 h-5 transition-transform', {
              'rotate-90': expanded,
            })}
          />
        )}
      </Link>
      {subItems.length > 0 && (
        <Collapse open={expanded}>
          <div className={'w-full pl-8'}>
            {subItems.map((item) => (
              <SidebarItem {...item} key={item.to} isSubItem={true} />
            ))}
          </div>
        </Collapse>
      )}
    </>
  );
};

export default SidebarItem;
