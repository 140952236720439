import { useState, type HTMLProps, type ReactNode } from 'react';
import { domAnimation, LazyMotion, m } from 'framer-motion';

type CollapseProps = {
  children: ReactNode;
  open: boolean;
  rest?: HTMLProps<HTMLDivElement>;
};

export const Collapse = ({ children, open, rest }: CollapseProps) => {
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const animate = {
    transition: { type: 'tween' },
    height: open ? 'auto' : 0,
  };

  const handleAnimationStart = () => {
    setAnimationCompleted(false);
  }
  const handleAnimationComplete = ({ height }: typeof animate) => {
    if (height === 'auto') setAnimationCompleted(true);
  };

  return (
    <LazyMotion features={domAnimation} strict>
      <div aria-expanded={open} {...rest}>
        <m.div
          style={{ overflow: open && animationCompleted ? 'visible' : 'hidden' }}
          initial={{ height: 0, opacity: 1 }}
          animate={animate}
          exit={{ height: 0, opacity: 1 }}
          onAnimationStart={handleAnimationStart}
          onAnimationComplete={handleAnimationComplete}
        >
          {children}
        </m.div>
      </div>
    </LazyMotion>
  );
};
