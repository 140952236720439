import { createFileRoute, Outlet } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getProviderQuery } from '@/features/administrator-panel/provider-view/api/providerViewApi.ts';

export const Route = createFileRoute('/admin/providers/$providerId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getProviderQuery(params.providerId)),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData ? loaderData.name : 'Account',
      },
    ],
  }),
  component: Outlet,
});
