import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/testing/')({
  beforeLoad: () => {
    throw redirect({
      to: '/testing/users',
      replace: true
    })
  },
})
