import { z } from 'zod';

import { applicationStatusFilters, applicationTypeFilters } from '@/common/const/applicationFilterOptions.ts';
import { objectKeys } from '@/lib/objectKeys.ts';
import type { Components } from '@/openapi';

export const applicationFilters = z.object({
  search: z.string().optional(),
  statusFilter: z
    .array(
      z.enum<
        Components.Schemas.ApplicationStatus,
        [
          Components.Schemas.ApplicationStatus,
          ...Components.Schemas.ApplicationStatus[],
        ]
      >(objectKeys(applicationStatusFilters)),
    )
    .optional()
    .default(['PENDING_APPROVAL']),
  type: z
    .array(
      z.enum<
        Components.Schemas.ApplicationType,
        [
          Components.Schemas.ApplicationType,
          ...Components.Schemas.ApplicationType[],
        ]
      >(objectKeys(applicationTypeFilters)),
    )
    .optional()
    .default([]),
  tags: z.array(z.string()).optional().default([]),
});
