import { MagnifyingGlassIcon } from '@heroicons/react/24/outline/index';
import {
  RegisteredRouter,
  RoutePaths,
  useMatch,
  useNavigate,
} from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { Input, InputProps } from '@/components/ui/Input.tsx';

export type SearchWidgetProps<TDefaultFrom extends RoutePaths<RegisteredRouter['routeTree']>> = {
  route: TDefaultFrom;
} & Omit<InputProps<'input'>, 'onChange' | 'value'>;

export const SearchWidget = <
  TDefaultFrom extends RoutePaths<RegisteredRouter['routeTree']>,
>({
  route,
  size,
  ...props
}: SearchWidgetProps<TDefaultFrom>) => {
  const params = useMatch({ strict: false }).search;
  const navigate = useNavigate({ from: route });
  const [search, setSearch] = useState(
    (params as { search?: string }).search ?? undefined,
  );

  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 200);

  useEffect(() => {
    setDebouncedSearch(search);
  }, [search, setDebouncedSearch]);

  useEffect(() => {
    if (debouncedSearch !== undefined) {
      // @ts-expect-error insufficient typing for this to be freeform
      void navigate({
        search: (prev) => ({
          ...prev,
          search: debouncedSearch === '' ? undefined : debouncedSearch,
        }),
      });
    }
  }, [debouncedSearch]);

  return (
    <Input
      size={size || 'lg'}
      inputClassName={'rounded-3xl'}
      icon={MagnifyingGlassIcon}
      {...props}
      value={search === undefined ? '' : search}
      onChange={(e) => setSearch(e.target.value)}
    />
  );
};
