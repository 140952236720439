import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { APPLICATION_TYPE_LABELS } from '@/common/i18n/applicationTypeLabels.ts';
import { getApplicationQuery } from '@/features/administrator-panel/review-application/api/reviewApi.ts';

export const Route = createFileRoute('/admin/applications/$applicationId')({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getApplicationQuery(params.applicationId)),
  head: ({ loaderData }) => ({
    meta: [
      {
        title: loaderData
          ? `${loaderData.lastName} ${APPLICATION_TYPE_LABELS[loaderData?.applicationType]}`
          : 'Customer Application',
      },
    ],
  }),
});
