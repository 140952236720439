import { createFileRoute, Outlet } from '@tanstack/react-router';

export const Route = createFileRoute('/admin/providers')({
  head: () => ({
    meta: [
      {
        title: 'Providers',
      },
    ],
  }),
  component: Outlet,
});
