import { Dialog, Transition } from '@headlessui/react';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { Fragment, ReactNode } from 'react';

import { cn } from '@/lib/utils.ts';

type MobileSidebarSurfaceProps = {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
};

const MobileSidebarSurface = ({
  children,
  open,
  onClose,
}: MobileSidebarSurfaceProps) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" onClose={onClose}>
      <Transition.Child
        as={Fragment}
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-hidden">
        <div className="absolute inset-0 overflow-hidden">
          <div className="pointer-events-none fixed inset-y-0 left-0 flex max-w-xs pr-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div
                    className={cn(
                      'absolute right-0 top-0 -mr-16 flex pr-2 pt-2 sm:pr-4',
                      {
                        hidden: !open,
                      },
                    )}
                  >
                    <button
                      type="button"
                      className="relative rounded-md text-white focus:outline-none focus:ring-2 focus:ring-white"
                      onClick={onClose}
                    >
                      <span className="absolute inset-2.5" />
                      <span className="sr-only">Close panel</span>
                      <XCircleIcon className="h-12 w-12" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="relative flex-1">{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);

export default MobileSidebarSurface;
