import { createFileRoute, redirect } from '@tanstack/react-router';
import { toast } from 'react-hot-toast';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { checkAuthQueryOptions } from '@/features/provider-panel/api/providerPanelApi.ts';

export const Route = createFileRoute('/provider/$providerId')({
  beforeLoad: async (params) => {
    await requireAuthenticated(params);
    try {
      await crownQueryClient.fetchQuery(
        checkAuthQueryOptions(params.params.providerId),
      );
    } catch (error) {
      toast.error('You are not authorized to access this page', {
        id: 'unauthorized',
      });
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  head: () => ({ meta: [{ title: 'Provider Home' }] }),
});
