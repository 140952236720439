import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { getDepositAccountsCount } from '@/features/portal/api/user-deposit-accounts.ts';

export const Route = createFileRoute('/portal')({
  beforeLoad: (params) => requireAuthenticated(params),
  loader: () => crownQueryClient.fetchQuery(getDepositAccountsCount),
  head: () => ({ meta: [{ title: 'Home' }] }),
});
