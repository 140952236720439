import {
  DefaultError,
  infiniteQueryOptions,
  keepPreviousData,
  MutationOptions,
  queryOptions,
} from '@tanstack/react-query';

import { crownApi, crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { Components } from '@/openapi';

export const getApplicationQuery = (applicationId: string) =>
  queryOptions({
    queryFn: async () =>
      (await crownApi.ApplicationReview_getApplication({ applicationId })).data,
    queryKey: ['applications', 'application', { applicationId }],
  });

export const approveApplicationMutation: MutationOptions<
  Components.Schemas.GetApplicationReviewResponse,
  DefaultError,
  { applicationId: string; prodCode?: string }
> = {
  mutationFn: async ({ applicationId, prodCode }) =>
    (
      await crownApi.ApplicationReview_approveApplication(
        { applicationId },
        { prodCode },
      )
    ).data,
  onSettled: () => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['applications'],
    });
  },
};

export const denyApplicationMutation: MutationOptions<
  Components.Schemas.GetApplicationReviewResponse,
  DefaultError,
  { applicationId: string }
> = {
  mutationFn: async ({ applicationId }) =>
    (await crownApi.ApplicationReview_denyApplication({ applicationId })).data,
  onSettled: () => {
    void crownQueryClient.invalidateQueries({
      queryKey: ['applications'],
    });
  },
};

export const getDepositAccountAgreementDocumentQuery = (
  applicationId: string,
  polling: boolean = false,
) =>
  queryOptions({
    refetchInterval: polling ? 1000 : 60000,
    queryFn: async () =>
      (
        await crownApi.ApplicationReview_getDepositAccountAgreementDocumentDetails(
          {
            applicationId,
          },
        )
      ).data,
    queryKey: ['applications', 'application', { applicationId }, 'agreement'],
  });

export const listApplicationTagsQuery = ({
  search,
  limit,
}: Omit<Components.Schemas.AdminListApplicationTagsRequestDto, 'page'>) =>
  infiniteQueryOptions({
    queryFn: async ({ pageParam = 1 }) => {
      return (
        await crownApi.AdminApplicationTag_listTags({
          page: pageParam,
          search,
          limit,
        })
      ).data;
    },
    queryKey: ['applicationTags', 'list', { search, limit }],
    initialPageParam: 1,
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    placeholderData: keepPreviousData,
  });
