import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { queryUserDepositAccount } from '@/features/portal/deposit-accounts/api/user-deposit-accounts.ts';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(
      queryUserDepositAccount(params.depositAccountId),
    ),
  head: ({ loaderData }) => ({
    meta: [{ title: loaderData ? loaderData.nickname : 'Account' }],
  }),
});
