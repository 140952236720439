import { createFileRoute, Outlet } from '@tanstack/react-router';

import { ErrorState } from '@/components/ui/states/ErrorState.tsx';
import { LoadingIndicator } from '@/features/portal/deposit-accounts/account/components/LoadingIndicator.tsx';

export const Route = createFileRoute('/portal/deposit-account')({
  errorComponent: ErrorState,
  pendingComponent: LoadingIndicator,
  component: Outlet,
  head: () => ({ meta: [{ title: 'Deposit Accounts' }] }),
});
