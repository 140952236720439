import { LifebuoyIcon } from '@heroicons/react/20/solid';
import { SignOut } from '@phosphor-icons/react';
import { Link } from '@tanstack/react-router';
import { ReactNode, useContext, useState } from 'react';

import { useUserSession } from '@/common/user-session/useUserSession';
import { Button } from '@/components/ui/Button.tsx';
import { GetHelpDialog } from '@/components/ui/layout/dashboard-layout/GetHelpDialog.tsx';
import { SidebarContext } from '@/components/ui/layout/dashboard-layout/sidebar/SidebarContext.ts';
import SidebarItem, {
  SidebarItemProps,
} from '@/components/ui/layout/dashboard-layout/sidebar/SidebarItem.tsx';
import { cn } from '@/lib/utils.ts';

type SidebarProps = {
  userTitle?: string;
  navbarItems: (SidebarItemProps & { show?: boolean })[];
  globalNavbarItems?: (SidebarItemProps & { show?: boolean })[];
  footer?: ReactNode;
};

const Sidebar = ({
  userTitle,
  navbarItems,
  globalNavbarItems = [],
  footer,
}: SidebarProps) => {
  const { user } = useUserSession();
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);
  const sidebarContext = useContext(SidebarContext);

  return (
    <div
      className={cn(
        'flex flex-col justify-between h-full',
        !!import.meta.env.VITE_TANSTACK_DEV_TOOLS && 'pb-14',
      )}
    >
      <div>
        {!!user && (
          <p className="w-full text-center text-lg font-semibold mt-4">{`Hello, ${user?.firstName}`}</p>
        )}
        {userTitle && (
          <p className="w-full text-center text-sm text-gray-400">
            {userTitle}
          </p>
        )}
        {!!user && (
          <>
            <div className="flex justify-center my-2 mb-4 items-center space-x-1">
              <Button
                size="sm"
                variant="outline"
                onClick={() => setSupportDialogOpen(true)}
              >
                <LifebuoyIcon className="w-5 h-5 mr-2" />
                Get Help
              </Button>
              <Link to="/logout">
                <Button size="sm" variant="outline">
                  <SignOut className="w-5 h-5 mr-2" />
                  Log out
                </Button>
              </Link>
            </div>

            <div className="w-full flex justify-center box-content">
              <div className="h-1.5 rounded flex-1 bg-slate-200 mx-2" />
            </div>
          </>
        )}

        <div className="mt-2 unmask">
          {navbarItems
            .filter((item) => item.show === undefined || item.show)
            .map((item) => (
              <SidebarItem
                key={item.to}
                {...item}
                onClick={() => sidebarContext?.closeSidebar?.()}
              />
            ))}
        </div>
      </div>

      <div className="flex flex-col">
        <div className="w-full flex justify-center box-content">
          <div className="h-1.5 rounded flex-1 bg-slate-200 mx-2" />
        </div>
        <div className="mt-2">
          {globalNavbarItems.map((item) => (
            <SidebarItem key={item.to} {...item} />
          ))}
        </div>
        <div className="mt-2">{footer}</div>
      </div>
      <GetHelpDialog
        onClose={() => setSupportDialogOpen(false)}
        open={supportDialogOpen}
      />
    </div>
  );
};

export default Sidebar;
