import { useDescope } from '@descope/react-sdk';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useUserSessionState } from '@/common/user-session/state';
import { getUserSessionOptions } from '@/common/user-session/userSessionApi';

// See useUserSessionState for detailed notes
export const useUserSession = () => {
  const initDescope = useUserSessionState((state) => state.initDescope);
  const descope = useDescope();
  useEffect(
    () => {
      initDescope(descope);
    },
    [
      /* Keep empty */
    ],
  );

  const checkSession = useUserSessionState((state) => state.checkSession);
  const sessionDetails = checkSession();
  const sessionToken = sessionDetails.ready ? descope.getSessionToken() : null;

  const logout = useUserSessionState((state) => state.logout);
  const magicLink = useUserSessionState((state) => state.magicLink);

  // This should already be preloaded by the root loader
  const userSessionQuery = useQuery({
    ...getUserSessionOptions,
    enabled: !!sessionToken,
  });

  useEffect(() => {
    if (userSessionQuery.data) {
      Sentry.setUser({
        username: `${userSessionQuery.data.firstName} ${userSessionQuery.data.lastName}`,
        id: userSessionQuery.data.id,
        email: userSessionQuery.data.email,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [userSessionQuery.data]);

  return {
    sessionToken,
    user: sessionToken ? userSessionQuery.data : null,
    isFetching: !sessionToken || userSessionQuery.isFetching,
    error: userSessionQuery.error,
    refetch: userSessionQuery.refetch,
    logout,
    magicLink,
  };
};
