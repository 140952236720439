import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { listApiKeysInfiniteQuery } from '@/features/administrator-panel/api-keys/api/apiKeysApi.ts';
import { API_KEYS_PER_PAGE } from '@/features/administrator-panel/api-keys/APIKeys.tsx';

export const Route = createFileRoute('/admin/configuration/api-keys')({
  loader: () =>
    crownQueryClient.ensureInfiniteQueryData(
      listApiKeysInfiniteQuery(API_KEYS_PER_PAGE),
    ),
  head: () => ({ meta: [{ title: 'API Keys' }] }),
});
