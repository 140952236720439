import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { HTMLAttributes } from 'react';

import HelperText from '@/components/ui/HelperText.tsx';
import { ErrorType } from '@/components/ui/Input.tsx';
import InputErrorMessage from '@/components/ui/InputErrorMessage.tsx';
import InputLabel from '@/components/ui/InputLabel.tsx';
import { cn } from '@/lib/utils.ts';

type SelectProps<TValue extends string> = {
  label: string;
  value: TValue | '';
  onChange: (value: TValue) => void;
  placeholder?: string;
  options: {
    value: TValue;
    label: string;
  }[];
  error?: ErrorType;
  helperText?: string;
  disabled?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'onChange'>;

export const Select = <TValue extends string>({
  label,
  value,
  onChange,
  options,
  placeholder,
  helperText,
  disabled,
  ...props
}: SelectProps<TValue>) => {
  const selectedValue = options.find((option) => option.value === value);

  return (
    <Listbox value={value} onChange={onChange} disabled={disabled}>
      {({ open, disabled }) => (
        <div {...props}>
          <InputLabel>{label}</InputLabel>
          <div className="relative mt-1">
            <ListboxButton
              className={cn(
                'relative w-full cursor-default rounded-md bg-white py-[calc(theme(spacing[2.5])+1px)] pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-input focus:outline-none focus:ring-2 focus:ring-primary sm:text-sm sm:leading-6',
                { 'border-error': Boolean(props.error) },
                { 'text-muted-foreground': disabled },
              )}
            >
              <span className="block truncate">
                {selectedValue?.label || placeholder || 'Select...'}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <ListboxOption
                    key={option.value}
                    className={({ focus }) =>
                      cn(
                        focus ? 'bg-primary text-white' : '',
                        !focus ? 'text-gray-900' : '',
                        'relative cursor-default select-none py-2 pl-3 pr-9',
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, focus }) => (
                      <>
                        <span
                          className={cn(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {option.label}
                        </span>

                        {selected ? (
                          <span
                            className={cn(
                              focus ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
          <InputErrorMessage message={props.error} />
          <HelperText>{helperText}</HelperText>
        </div>
      )}
    </Listbox>
  );
};
