import './App.css';
import './overwrites.css';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet, ScrollRestoration } from '@tanstack/react-router';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import Favicon from 'react-favicon';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { useBreadcrumbs } from '@/common/hook/useBreadcrumbs.ts';
import { useTheming } from '@/common/hook/useTheming.ts';

const App = () => {
  const data = useTheming();
  const breadcrumbs = useBreadcrumbs();

  if (!data) {
    return null;
  }

  const breadcrumbsString = breadcrumbs
    .map((breadcrumb) => breadcrumb.title)
    .join(' • ');

  return (
    <HelmetProvider>
      <Favicon url={data.favicon} />

      <Helmet defer={false}>
        <title>
          {breadcrumbsString || data.applicationName}
        </title>
      </Helmet>

      <div className={'w-full min-h-screen'}>
        <ScrollRestoration />
        <Outlet />
      </div>
      {!!import.meta.env.VITE_DEVTOOLS && <TanStackRouterDevtools />}
      {!!import.meta.env.VITE_DEVTOOLS && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
    </HelmetProvider>
  );
};

export default App;
