import { createFileRoute, stripSearchParams } from '@tanstack/react-router';
import { zodValidator } from '@tanstack/zod-adapter';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { applicationFilters } from '@/common/schema/applicationFiltersSchema.ts';
import { getZodDefaults } from '@/common/util/getZodDefaults.ts';
import { APPLICATIONS_PER_PAGE } from '@/components/ui/applications/constants.ts';
import { listApplicationsInfiniteQuery } from '@/features/administrator-panel/applications/api/applicationsListApi.ts';

export const Route = createFileRoute('/admin/applications/')({
  validateSearch: zodValidator(applicationFilters),
  search: {
    middlewares: [stripSearchParams(getZodDefaults(applicationFilters))],
  },
  loader: () =>
    crownQueryClient.ensureInfiniteQueryData(
      listApplicationsInfiniteQuery({
        limit: APPLICATIONS_PER_PAGE,
        filters: { search: '', status: ['PENDING_APPROVAL'] },
      }),
    ),
});
