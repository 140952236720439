import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import { z } from 'zod';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { listUserApplicationsQuery } from '@/features/portal/start-home/api/listApplications.ts';

export const Route = createFileRoute('/portal/start')({
  validateSearch: z.object({
    provider: z.string().optional(),
  }),
  beforeLoad: async (ctx) => {
    const user = await requireAuthenticated(ctx);
    if (user?.showWelcomePage) {
      throw redirect({
        to: '/welcome',
        search: {
          continueSearch: ctx.search,
          continueTo: ctx.location.pathname,
        },
      });
    }
  },
  loader: () => crownQueryClient.ensureQueryData(listUserApplicationsQuery),
  head: () => ({ meta: [{ title: 'Welcome' }] }),
  component: () => (
    <>
      <Outlet />
    </>
  ),
});
