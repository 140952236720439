import { createRouter } from '@tanstack/react-router';

import { routeTree } from '@/routeTree.gen.ts';
import { trackMatomoPageChange } from '../analytics/matomo.ts';

export const router = createRouter({
  routeTree,
  defaultPreload: "intent"
});

router.subscribe('onResolved', () => {
  trackMatomoPageChange(router);
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}
