import { Outlet } from '@tanstack/react-router';

import DashboardContentLayout from '@/components/ui/layout/dashboard-layout/DashboardContentLayout.tsx';
import Sidebar from '@/components/ui/layout/dashboard-layout/sidebar/Sidebar.tsx';
import { Users, Wrench } from '@phosphor-icons/react';

export const Testing = () => {
  return (
    <DashboardContentLayout
      content={<Outlet />}
      sidebar={<Sidebar navbarItems={[
        {
          to: '/testing/users',
          label: 'Users',
          icon: Users
        },
        {
          to: '/testing/system-tools',
          label: 'System Tools',
          icon: Wrench
        }
      ]} />}
    />
  );
};
