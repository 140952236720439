import { Link, NavigateOptions } from '@tanstack/react-router';
import { HTMLAttributes } from 'react';

import { cn } from '@/lib/utils.ts';

export type QueriedListItemProps = {
  link?: Pick<NavigateOptions, 'to' | 'search' | 'params'>;
  hoverEffect?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const QueriedListItem = ({
  link,
  children,
  hoverEffect = true,
  ...props
}: QueriedListItemProps) => {
  return (
    <div
      className={cn(
        'relative',
        { 'hover:bg-primary-foreground transition-colors': hoverEffect },
        props.className,
      )}
    >
      {children}
      {link && (
        <Link {...link} className={'absolute inset-x-0 -top-px bottom-0'} />
      )}
    </div>
  );
};
