import { Wrench } from '@phosphor-icons/react';

import { DashboardContentSurface } from '@/components/ui/layout/dashboard-layout/DashboardContentSurface.tsx';
import DashboardHeader from '@/components/ui/layout/dashboard-layout/DashboardHeader.tsx';
import { CreateTestTransaction } from '@/testing/system-tools/CreateTestTransaction.tsx';

export const SystemTools = () => {
  return (
    <>
      <DashboardHeader>
        <Wrench className={'w-8 h-8 mr-2'} />
        System Tools
      </DashboardHeader>

      <DashboardContentSurface header={'Transaction Tools'} className={'overflow-visible'}>
        <CreateTestTransaction />
      </DashboardContentSurface>
    </>
  );
};
