import { createFileRoute, redirect } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { requireAuthenticated } from '@/common/api/requireAuthenticated.ts';
import { checkAuthQuery } from '@/features/administrator-panel/api/adminstratorPanelApi.ts';

export const Route = createFileRoute('/admin')({
  beforeLoad: async (params) => {
    await requireAuthenticated(params);
    try {
      await crownQueryClient.fetchQuery(checkAuthQuery);
    } catch (error) {
      throw redirect({
        to: '/',
        replace: true,
      });
    }
  },
  head: () => ({ meta: [{ title: 'Admin' }] }),
});
