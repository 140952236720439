import { createFileRoute } from '@tanstack/react-router';

import { crownQueryClient } from '@/common/api/crownQueryClient.ts';
import { getWireFeeQuery } from '@/features/portal/deposit-accounts/account/api/userDepositAccountTransfersAPI.ts';

export const Route = createFileRoute(
  '/portal/deposit-account/$depositAccountId/home/wires',
)({
  loader: ({ params }) =>
    crownQueryClient.ensureQueryData(getWireFeeQuery(params.depositAccountId)),
  head: () => ({ meta: [{ title: 'Wires' }] }),
});
