import { forwardRef } from 'react';
import CurrencyInputComponent from 'react-currency-input-field';
import { Input, InputProps } from './Input';
import { useFormContext } from 'react-hook-form';

const CurrencyInput = forwardRef<any, InputProps<typeof CurrencyInputComponent>>(
    (props, ref) => {
        const { setValue } = useFormContext() || {};
        return <Input
            {...props}
            InputComponent={CurrencyInputComponent}
            ref={ref}
            prefix='$'
            onValueChange={(value: string | undefined) => {
                props.name && setValue && setValue(props.name, value);
            }}
        />;
    }
);

export default CurrencyInput;
